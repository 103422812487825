import {datasa} from '~/assets/output'
import { Table } from '~/components/Table';
import { tableHeaders } from '~/components/tableHeaders'
import logo from "~/assets/images/logo.png";
import logoWhite from "~/assets/images/logo_white.png";
import { useResponsive } from "ahooks";
import styled from 'styled-components';
import instagram from "~/assets/images/instagram_white.svg";
import youtube from "~/assets/images/youtube_white.svg";
import twitter from "~/assets/images/twitter_white.svg";
import ecohouse from "~/assets/images/logo_ecohouse.png";

const Header = () => {
  return (
    <div className="max-w-screen-xl mx-auto px-8 xl:px-0">
      <header className="py-6 flex">
        <div className="flex-1">
          <a href="/">
            <img src={logo} className="h-10" />
          </a>
        </div>
        <div className="flex items-stretch">
        <Instagram
            href="https://instagram.com/eco.house"
            className="p-2 align-middle self-center h-8 w-8 rounded-full mx-1"
          >
            <img src={instagram} className="h-4 text-white" />
          </Instagram>
          <a
            href="https://twitter.com/ecohouseok"
            className="p-2 align-middle self-center bg-blue h-8 w-8 rounded-full mx-1"
          >
            <img src={twitter} className="h-4" />
          </a>
          <a
            href="https://youtube.com/ecohouse"
            className="p-2 align-middle self-center bg-red h-8 w-8 rounded-full mx-1"
          >
            <img src={youtube} className="h-4" />
          </a>
        </div>
      </header>
    </div>
  );
};

const Instagram = styled.a`
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
`;

const TableStyles = styled.div`
  table {
    thead {
      tr {
        th:first-child {
          width: 50%;
        }
      }
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 1rem;
      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`;


export default function Index() {
  const isMobile = useResponsive();
  datasa.sort(function(a,b){
    return new Date(b.publish_date) - new Date(a.publish_date);
  });
  return (
    <>
    <Header />
    <div className="-mt-16 min-h-48 px-8 xl:px-0">
      <div className="max-w-screen-xl mx-auto pt-28 pb-8">
        <div className="text-3xl text-black font-bold">
          ¡Bienvenidxs a la gran Base de Datos SocioAmbientales!
        </div>
        <div className="text-lg mt-8 mb-3 leading-6 text-black">
          En base a nuestra filosofía, el acceso a la información es una de las
          claves para transformar la realidad, por lo que decidimos lanzar una
          gran base de datos socioambientales para que podamos compartir y
          acceder libremente al conocimiento. ¡Es de gran utilidad para
          activistas, periodistas, gobiernos, escuelas y estudiantes!
        </div>
      </div>
    </div>
    <div className="max-w-screen-xl mx-auto p-8 xl:p-0">
      <TableStyles>
        <Table columns={tableHeaders} data={datasa} isMobile={isMobile}/>
      </TableStyles>
    </div>
    <div className="max-w-screen-xl mx-0 md:mx-8 xl:mx-auto mt-4 mb-0 sm:mb-20 bg-blue p-8 sm:rounded-lg">
      <div className="max-w-screen-xl mx-auto">
        <div className="text-3xl text-white font-bold pb-6">
          COLABORATIVA Y SOLIDARIA
        </div>
        <div className="text-lg leading-6 mb-12 text-white">
          Datasa.info es una base de datos colaborativa, libre y solidaria. El
          único fin que posee es compartir conocimientos para que elevemos
          nuestra conciencia colectiva. NO POSEE FINES DE LUCRO. Desde ya, si
          tenés datos para aportar, sentite libre de enviarlos para que los
          agreguemos a la plataforma.
          <br />
          ¡Muchas gracias!
        </div>
        <a
          className="rounded-md font-bold bg-white text-blue py-4 px-8 w-max"
          href="https://bit.ly/agregar-datasa"
        >
          Agregar Datos
        </a>
      </div>
    </div>
    <div className="bg-black">
      <div className="max-w-screen-xl mx-auto px-8 xl:px-0">
        <footer className="py-6 flex">
          <a href="https://ecohouse.org.ar" className="flex-1">
            <img src={ecohouse} className="h-12" />
          </a>
          <div className="flex items-stretch">
            <a
              href="https://instagram.com/eco.house"
              className="p-2 align-middle self-center"
            >
              <img src={instagram} className="h-4" />
            </a>
            <a
              href="https://twitter.com/ecohouseok"
              className="p-2 align-middle self-center"
            >
              <img src={twitter} className="h-4" />
            </a>
            <a
              href="https://youtube.com/ecohouse"
              className="p-2 align-middle self-center"
            >
              <img src={youtube} className="h-4" />
            </a>
          </div>
        </footer>
      </div>
    </div>
    </>
  );
}

export const tableHeaders = [
  {
    Header: "Eje",
    accessor: "axis",
    show: false,
  },
  {
    Header: "Category",
    accessor: "category",
    show: false,
  },
  {
    Header: "Sub Category",
    accessor: "sub_category",
    show: false,
  },
  {
    Header: "Section",
    accessor: "section",
    show: false,
  },
  {
    Header: "Dato Cuantitativo",
    accessor: "information",
    show: true,
  },
  {
    Header: "Nivel",
    accessor: "level",
    show: true,
  },
  {
    Header: "Sub Nivel",
    accessor: "sub_level",
    show: true,
  },
  {
    Header: "Fecha de Informe",
    accessor: "publish_date",
    show: true,
  },
  {
    Header: "Fecha del Dato",
    accessor: "info_date",
    show: true,
  },
  {
    Header: "Organización",
    accessor: "organization",
    show: true,
  },
  {
    Header: "Autor del documento de donde se obtuvo el dato",
    accessor: "source",
    show: true,
  },
  {
    Header: "Link completo",
    accessor: "link",
    show: true,
  },
  {
    Header: "Tipo de fuente",
    accessor: "info_type",
    show: true,
  },
];

import React from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
// import styled from "styled-components";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`🔍 ${count} resultados...`}
        className="p-2 bg-gray-200 rounded-lg lg:w-3/12 border-solid border-black border w-full"
      />
    </>
  );
}


export const Table = ({ columns, data, isMobile }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    preFilteredRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        hiddenColumns: columns.map((column) => {
          if (
            column.show === false ||
            (!!isMobile && !isMobile.sm && column.accessor !== "information")
          ) {
            return column.accessor || column.id;
          }
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const [axis, setAxis] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");

  const optionsAxis = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values.axis);
    });
    return [...options.values()];
  }, [preFilteredRows]);

  const optionsCategories = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values.axis === axis) {
        options.add(row.values.category);
      }
    });
    return [...options.values()];
  }, [axis]);

  const optionsSubCategories = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values.category === category) {
        options.add(row.values.sub_category);
      }
    });
    return [...options.values()];
  }, [category]);

  const resetFilters = () => {
    setFilter("axis", "");
    setFilter("category", "");
    setFilter("sub_category", "");
    setAxis("");
    setCategory("");
    setSubCategory("");
  };

  const handleAxis = (value) => {
    setAxis(value);
    setCategory("");
    setSubCategory("");
    setFilter("axis", value);
    setFilter("category", "");
    setFilter("sub_category", "");
  };

  const handleCategories = (value) => {
    setCategory(value);
    setSubCategory("");
    setFilter("category", value);
    setFilter("sub_category", "");
  };

  const handleSubCategory = (value) => {
    setSubCategory(value);
    setFilter("sub_category", value);
  };

  return (
    <>
      <div>
        <div className="p-4 mb-8 bg-red text-white rounded-lg sm:hidden text-sm">
          <b>Atención:</b> en la versión mobile solo contarás con el dato y el
          link a la fuente original. Para ver el año y toda la info, deberás
          ingresar desde una PC o tablet.
          <br />
          <b>¡Muchas gracias!</b>
        </div>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <select
          className="rounded-lg p-2 w-full lg:w-3/12 border-solid border-black border h-[42px]"
          onChange={(e) => handleAxis(e.target.value)}
        >
          <option value="">Todos los Datos</option>
          {optionsAxis.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
        {!!axis && (
          <>
            <select
              className="rounded-lg p-2 w-full lg:w-3/12  border-solid border-black border h-[42px]"
              onChange={(e) => handleCategories(e.target.value)}
            >
              <option value="">Todos los Datos</option>
              {optionsCategories.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </>
        )}
        {!!category && (
          <>
            <select
              className="rounded-lg p-2 w-full lg:w-3/12  border-solid border-black border h-[42px]"
              onChange={(e) => handleSubCategory(e.target.value)}
            >
              <option value="">Todos los Datos</option>
              {optionsSubCategories.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </>
        )}
        {!!axis && (
          <>
            <button
              onClick={() => resetFilters()}
              className="border border-black rounded-full p-2"
            >
              ⏮
            </button>
          </>
        )}
      </div>
        </div>
        
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table
                className="min-w-full divide-y divide-gray-300"
                {...getTableProps()}
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={Math.random()}
                    >
                      {headerGroup.headers.map((column, key) => (
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-xs xl:text-sm font-semibold text-gray-400 uppercase"
                          {...column.getHeaderProps()}
                          key={Math.random()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                  {...getTableBodyProps()}
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={Math.random()}>
                        {row.cells.map((cell) => {
                          if (cell.column.id === "link") {
                            return (
                              <td {...cell.getCellProps()}>
                                <a
                                  className="text-blue underline"
                                  href={cell.value}
                                >
                                  Link
                                </a>
                              </td>
                            );
                          }
                          if (cell.column.id === "information") {
                            return (
                              <td key={Math.random()} {...cell.getCellProps()}>
                                
                                {isMobile?.sm ?
                                
                                  
                                  <>{cell.render("Cell")}</>
                                  :
                                  <>
                                  <div className="text-sm">
                                    {cell.render("Cell")}
                                  </div>
                                  <div className="flex space-x-2 my-2 text-xs">
                                  <a
                                    className="bg-blue border-2 border-blue px-2 py-1 font-bold text-white block w-max rounded-lg"
                                    href={cell.row.values.link}
                                  >
                                    Ver fuente
                                  </a>
                                  <div className="text-xs border-2 border-blue-500 px-2 py-1 text-blue-500 font-bold block w-max rounded-lg">{cell.row.values.level}</div>
                                  {cell.row.values.sub_level !== "-" && !!cell.row.values.sub_level && <div className="text-xs border-2 border-blue-400 px-2 py-1 text-blue-400 font-bold px-2 py-1 text-blue-400 block w-max rounded-lg">{cell.row.values.sub_level}</div> }
                                  </div>
                                </>
                                }
                              </td>
                            );
                          }
                          if (cell.column.id !== "information") {
                            return (
                              <td
                                key={Math.random()}
                                {...cell.getCellProps()}
                                className="text-xs"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          }
                          return (
                            <td key={Math.random()} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="pagination">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="bg-gray-300 rounded-full text-black w-8 h-8"
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="bg-gray-300 rounded-full text-black w-8 h-8"
        >
          {">"}
        </button>{" "}
        <span>
          Página{" "}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
            
          </strong>{" "}
        </span>
        <span>
          | Ir a Página:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            className="p-1 bg-white border border-black rounded-md"
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          className="p-2 bg-white border border-black rounded-md"
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};